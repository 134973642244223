.header {
  min-height: 0.625rem;
}
.header .t-logo {
  width: auto !important;
}
.header__helpBlock {
  width: 25%;
  display: block;
  height: 100%;
}
.header__helpBlock .t-help {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 15px !important;
}
.header__helpBlock .t-help:after {
  content: '\e182';
  margin-left: 0.5rem;
  font-family: 'tricons', Helvetica, Arial, sans-serif !important;
}

.header .t-right .t-author {
  align-items: center;
  justify-content: space-between;
}
.header .t-right .t-item:first-child {
  display: inline-flex;
  flex-direction: row !important;
  max-width: 75% !important;
  border-right: 1px solid #464646;
}
.header .t-right > .t-item:hover {
  background: #212222 !important;
}
.header .t-right .t-item:first-child {
  cursor: default;
  min-height: 100%;
  align-items: center;
}
.header .t-right .t-item:first-child .t-icon-caret-down {
  display: none;
}
.header .t-right .t-item span {
  display: block !important;
  text-align: left !important;
}
.header .t-right .t-item .t-avatar {
  min-width: 35px !important;
  height: 35px !important;
  font-size: 12px !important;
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  margin-right: 8px !important;
  line-height: 35px !important;
  text-transform: uppercase;
}
.header .t-right .t-label > span:first-child {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 18px !important;
}
.t-top-bar .t-menu .t-label {
  min-width: 248px !important;
}
.t-label {
  min-width: 170px !important;
}
.t-right .t-item {
  flex-direction: row !important;
  max-width: 100% !important;
  width: 75%;
}
.t-help {
  position: relative;
  font-weight: 600;
}
.helpBlock__helpList {
  position: absolute;
  width: 36%;
  right: 0.063rem;
  top: 3.375rem;
  box-shadow: 0px 5px 10px #cccccc;
  background: #ffffff;
}
.helpBlock__helpList li {
  text-align: left;
  color: #464646;
}
.helpBlock__helpList li {
  padding: 0.313rem 0;
  display: block;
}
.helpBlock__helpList li a {
  display: block;
  width: 100%;
  padding: 0.625rem;
}
.helpBlock__helpList li:hover {
  background: #bfebeb;
}
.show {
  background: #464646;
}
@-moz-document url-prefix() {
  .header .t-right .t-item .t-avatar {
    margin-top: 2.188rem;
  }
}
.tooltip {
  position: relative;
  display: contents;
}
.tooltip .t-item {
  border-left: 1px solid #464646;
  padding-left: 15px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 85%;
  background-color: #fff;
  color: #464646;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 20%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  top: 50px;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.tooltip .tooltiptext:after {
  content: '';
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-bottom-color: #cccccc;
  border-right-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.header .t-right .t-item:first-child .t-label span:nth-child(2) {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
