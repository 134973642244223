.t-input {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0 !important;
  width: 74.375rem;
  margin: 0 auto;
}
.t-input .t-icon-search {
  color: #464646 !important;
  padding-right: 0.313rem;
}
.t-input input {
  background: #e6e6e6 !important;
  border: none;
}
.t-input input::placeholder {
  color: #464646 !important;
  font-weight: 700;
}
