.card .card_userCard {
  background: white;
  min-height: 13.125rem;
}
.card .card_userCard:hover {
  box-shadow: 0px 1px 2px rgba(40, 65, 35, 0.1);
}
.card .card_userCard h5 {
  text-transform: capitalize;
}
.card .t-btn {
  background: #ffff;
  border: 1px solid #00afaf;
}
.card .t-timestamp {
  font-size: 0.75rem;
  display: inline-flex;
  line-height: 1rem;
}
.card .t-icon-history {
  font-size: 0.75rem;
}
.card .t-author {
  align-items: center;
  justify-content: space-between;
}
.card {
  margin: 0 auto;
  height: 26.5rem;
  overflow: hidden;
  width: 74.375rem;
}
.t-dialog .t-header {
  text-align: center;
  padding-bottom: 0 !important;
}
.t-dialog .t-content {
  display: none;
}
