footer {
  min-height: 4.375rem;
  display: block;
  clear: both;
}
.footer {
  border-top: 1px solid #ececec;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f7f7f7;
}

.footer__links li {
  border-left: 0.0625rem solid #a0a0a0;
  height: 0.9375rem;
}

.footer a {
  color: #006491;
}

.footer__links {
  list-style: none;
  justify-content: flex-end;
}

.footer__links li:first-child {
  border-left: none;
}

.footer__links li a {
  border-bottom: 0.0625rem solid #0192d6;
}
