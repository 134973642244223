.showMoreActive {
  height: auto !important;
  overflow: visible !important;
}
.showMoreClass {
  background: #fff;
  clear: both;
  cursor: pointer;
  width: 74.375rem;
  border: none;
  margin: 0 auto;
}
.backToTop {
  background: #ffff;
  border: none;
  padding: 0.625rem 0.938rem;
  position: fixed;
  bottom: 10%;
  right: 1.25rem;
  box-shadow: 0 0.438rem 0.563rem rgba(40, 65, 35, 0.1);
  cursor: pointer;
}
