.app-container {
  width: 40%;
  border: 1px solid #e6e5e5;
  background-color: #f9f8fa;
  margin-left: auto;
  margin-right: auto;
}

img {
  width: auto;
  height: 70px;
}

a {
  text-decoration: none;
}

.app-container p {
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  color: #ff2679;
}

.app-container div {
  border: 0.5px solid #fc2d7a;
  background: #ffffff;
  margin: 5%;
}

.App {
  margin-top: 20px;
  text-align: center;
}
.Error-left{
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.textinput {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee;
  display: none;
}

.no-outline:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*******css added for multiple user selector page*********/

.App a {
  text-decoration: none !important;
}
.App .t-container {
  background: none;
  box-shadow: none;
}

.t-dialog .t-header {
  font-family: 'Libre Franklin', Arial, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1em;
}
.App__BodyCenter {
  margin: 0 auto;
  width: 74.375rem;
}

.LoadingText {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 400px;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.t-error-page{
  display: flex;
}