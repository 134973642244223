.about {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  margin: 0 auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.3);
}
.about__model {
  background: white;
  width: 56.25rem;
  min-width: auto;
  max-width: calc(100% - 48px);
  margin: auto;
  padding: 0;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  -ms-align-self: flex-start;
  align-self: flex-start;
  position: relative;
  top: 13%;
}
.about__header {
  background: #f3f3f3;
  padding: 1.125rem 3rem;
}
.about__header p {
  padding: 0.625rem;
}
.row {
  width: 100%;
  float: left;
  text-align: left;
  padding: 1.25rem;
}
.col-8 {
  width: 65%;
  float: left;
}
.col-4 {
  width: 25%;
  float: right;
  text-align: left;
}
.col-4 ul li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.313rem 0;
}
.col-4 ul li:nth-child(3) {
  padding-bottom: 1.25rem;
}
.col-4 ul li:nth-child(4) a,
.col-4 ul li:nth-child(5) a {
  text-decoration: underline !important;
}
.col-4 ul li img {
  width: 0.938rem;
  height: 0.75rem;
}
.col-4 ul li h6 {
  margin: 0 0.625rem;
}
.col-4 ul li div {
  display: flex;
  align-items: center;
}
.about__footer {
  padding: 1.25rem 0;
  margin: 1.25rem;
  text-align: left;
  border-top: 1px solid #ececec;
  clear: both;
  color: #a0a0a0;
}
.about__cancel {
  float: right;
  padding: 0.813rem;
  border: none;
  background: #f3f3f3;
  cursor: pointer;
}
